<template>
  <v-dialog
    v-model="loadingDialog"
    persistent
    width="200"
  >
    <div class="loading-dialog-container">
      <div class="loading-dialog-text">Обработка запроса</div>
      <v-progress-linear
        indeterminate
        color="#EF7F1A"
      ></v-progress-linear>
    </div>
  </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      loadingDialog: false,
    }),
    methods: {
      startLoading() {
        this.loadingDialog = true;
      },
      stopLoading() {
        this.loadingDialog = false;
      },
    },
  }
</script>

<style lang="scss" scoped>
.loading-dialog-container {
  padding: 25px;
  text-align: center;
  background-color: #FFFFFF;
}
.loading-dialog-text {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
}
</style>