<template>
  <v-dialog v-model="alertDialog" persistent width="320">
    <div class="alert-container">
      <div class="close-alert-btn" @click="closeAlert">✖</div>
      <div class="alert-title">{{ alertInfo.alert_title || "" }}</div>
      <div class="alert-message">{{ alertInfo.alert_message || "" }}</div>
      <div class="alert-actions">
        <button
          v-if="alertInfo.cancel_btn_text"
          type="button"
          class="cancel-btn"
          :class="{ 'full-width-btn': !alertInfo.confirm_btn_text }"
          @click="closeAlert"
        >
          {{ alertInfo.cancel_btn_text || "Нет" }}
        </button>
        <button
          v-if="alertInfo.confirm_btn_text"
          type="button"
          class="confirm-btn"
          :class="{ 'full-width-btn': !alertInfo.cancel_btn_text }"
          @click="confirmAlert"
        >
          {{ alertInfo.confirm_btn_text || "Да" }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    alertInfo: Object,
    alertDialog: Boolean,
  },
  methods: {
    closeAlert() {
      this.$emit("emitCloseAlert");
    },
    confirmAlert() {
      this.$emit("emitConfirmAlert");
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-container {
  padding: 32px;
  position: relative;
  border-radius: 12px;
  background: #ffffff;
}
.close-alert-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}
.alert-title {
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
}
.alert-message {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.alert-actions {
  display: flex;
  justify-content: space-between;
}
.alert-actions > .cancel-btn,
.alert-actions > .confirm-btn {
  padding: 12px 32px;
  height: 48px;
  width: calc(50% - 4px);
  border-radius: 8px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}
.alert-actions > .cancel-btn {
  background: #ef7f1a;
}
.alert-actions > .confirm-btn {
  background: #90bc34;
}
.full-width-btn {
  width: 100% !important;
}
</style>