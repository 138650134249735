<template>
  <div class="no-products">
    <div class="no-products__container">
      <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.7353 15.1176C29.7353 22.9146 23.4146 29.2353 15.6176 29.2353C7.82068 29.2353 1.5 22.9146 1.5 15.1176C1.5 7.32068 7.82068 1 15.6176 1C23.4146 1 29.7353 7.32068 29.7353 15.1176Z" fill="#C8C8C8" fill-opacity="0.16"/>
        <path d="M33.5 33L25.6003 25.1003M29.7353 15.1176C29.7353 7.32068 23.4146 1 15.6176 1C7.82068 1 1.5 7.32068 1.5 15.1176C1.5 22.9146 7.82068 29.2353 15.6176 29.2353C23.4146 29.2353 29.7353 22.9146 29.7353 15.1176Z" stroke="#C8C8C8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <strong class="no-products__title">Нет товаров</strong>
      <p class="no-products__subtitle">
        Извините, но в этой категории временно нет товаров.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "NoProducts",
}
</script>

<style lang="scss" scoped>
.no-products {
  height: calc(100vh - 184px);
  display: flex;

  &__container {
    display: grid;
    place-items: center;
    max-width: 60vw;
    text-align: center;
    margin: auto;
  }
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-top: 1rem;
    margin-bottom: .2rem;
  }
  &__subtitle {
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #8E8E93;
  }
}
</style>
