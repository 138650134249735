<template>
  <div
    @touchstart.stop
    @touchend.stop
    @touchmove.stop
    class="slider-wrapper"
    :class="{ fullscreen: isImagesFullscreen }"
  >
    <img
      v-if="isImagesFullscreen"
      src="@/assets/marketplace/cancel_icon.svg"
      alt="close"
      class="header-right-icon"
      style="z-index: 2"
      @click="$emit('closeImages')"
    />
    <Flicking
      :options="{
        align: {
          panel: 0,
          camera: 16,
        },
      }"
      :tag="'div'"
      :viewportTag="'div'"
      :cameraTag="'div'"
      :plugins="plugins"
    >
      <template v-if="images && images.length">
        <div v-for="image in images" :key="image" class="slider-item">
          <div class="d-flex" @click="emitOpenFullscreenImage">
            <img
              v-if="image.image"
              :src="api_url + image.image"
              style="object-fit: contain"
              width="100%"
              height="100%"
            />
            <img
              v-else
              :src="api_url + image"
              style="object-fit: contain"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="slider-item">
          <div class="d-flex">
            <img
              src="@/assets/marketplace/no_image.svg"
              alt="no_image"
              style="object-fit: contain"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </template>
      <div slot="viewport" class="flicking-pagination"></div>
    </Flicking>
  </div>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import { Pagination } from "@egjs/flicking-plugins";

export default {
  props: {
    isImagesFullscreen: Boolean,
    images: Array,
    api_url: String
  },
  components: {
    Flicking,
  },
  data: () => ({
    plugins: [new Pagination({ type: "bullet" })],
  }),
  methods: {
    emitOpenFullscreenImage() {
      if (this.isImagesFullscreen === false) {
        this.$emit('openFullscreenImage');
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@egjs/vue-flicking/dist/flicking.css";
@import "~@egjs/flicking-plugins/dist/pagination.css";

.slider-wrapper {
  padding-top: 6px;
  user-select: none;

  .slider-item {
    margin-right: 28px;
    height: 200px;
    width: calc(100% - 32px);
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  .flicking-viewport {
    padding-bottom: 36px;
    transition: height 500ms;
  }
  .flicking-pagination {
    bottom: 16px;
  }
  .flicking-pagination-bullet {
    height: 8px;
    width: 8px;
    border: 1px solid #D9D9D9;
    background-color: #ffffff;
  }
  .flicking-pagination-bullet-active {
    background-color: #D9D9D9;
  }
  &.fullscreen {
    padding: 16px 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    background-color: #ffffff;

    .flicking-viewport {
      padding-bottom: 20px;
      height: calc(100vh - 32px) !important;
    }
    .flicking-pagination {
      bottom: 0;
    }
    .slider-item {
      height: calc(100vh - 52px);
    }
  }
}
</style>
